import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/SEO';
import PropTypes from 'prop-types';
import Layout from '../components/layout';
import { H1 } from 'components/Typography';
import Slices from 'components/Slices';
import Container from 'react-bootstrap/Container';
import Masthead from 'components/Masthead';
import { extractPageStyles, extractSocialMeta } from 'utils/fieldExtractors';

const propTypes = {
  data: PropTypes.object,
  errors: PropTypes.array,
};

const defaultProps = {
  data: null,
  errors: [],
};

function LandingPage({ data, ...props }) {
  const doc = data.prismic.homepagePage;
  if (!doc) {
    return null;
  }

  const { _meta, seoTitle, seoMetaDescription, pageTitle, body } = doc;

  const pageName = pageTitle ? pageTitle[0].text : '';

  return (
    <Layout pageStyles={extractPageStyles(doc)}>
      <SEO
        title={seoTitle || pageName}
        description={seoMetaDescription}
        lang={_meta}
        socialMeta={extractSocialMeta(doc)}
      />
      <Masthead data={doc} />
      <Slices slices={body} {...props} />
    </Layout>
  );
}

export const query = graphql`
  query LandingPageQuery($uid: String!, $lang: String!) {
    prismic {
      homepagePage: homepage(uid: $uid, lang: $lang) {
        pageTitle: page_title
        seoTitle: seo_title
        seoMetaDescription: seo_meta_description
        ogTitle: og_title
        ogImage: og_image
        ogDescription: og_description
        twitterCard: twitter_card
        twitterImage: twitter_image
        twitterTitle: twitter_title
        twitterDescription: twitter_description
        appButtonDownloads: app_button_downloads
        mastBody: masthead_body
        mastHeading: masthead_heading
        mastImage: masthead_image
        mastGradient: masthead_image_gradient
        mastMobileGradient: masthead_mobile_image_gradient
        mastCtaText: masthead_cta_text
        mastCtaLink: masthead_cta_link {
          ...LinkFragment
        }
        mediaButtonDownloads: media_button_downloads
        pageBackgroundColor: page_background_color
        pageBodyTextColor: page_body_text_color
        body {
          ...LPBasicCtaFragment
          ...LPBlockStyleFragment
          ...LPCarouselFragment
          ...LPCenteredFeatureFragment
          ...LPCtaBannerFragment
          ...LPDifferentiatorFragment
          ...LPFeaturedImageLeftRightFragment
          ...LPFeaturedOnFragment
          ...LPLearnBlockFragment
          ...LPMultiBlockFragment
          ...LPReviewsFragment
          ...LPTableFragment
          ...LPTeamBioFragment
          ...LPTextBlockFragment
          ...LPTwoBlockFragment
        }
        _meta {
          lang
        }
      }
    }
  }
`;

LandingPage.propTypes = propTypes;
LandingPage.defaultProps = defaultProps;

export default LandingPage;
